.optional-venues {
  &__heading {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  &__button {
    font-family: "Object Sans", sans-serif;

    &.btn-link,
    &.btn-link:hover {
      color: #5816ff;
    }

    &--fw-500 {
      font-weight: 500;
    }

    &.btn-link:disabled,
    &.btn-link.disabled {
      color: #333;
      opacity: 1;
    }
  }
}
