body {
  font-family: $font-family-raleway;
}

.main-content {
  margin-top: 1.5rem;
}

$quote-summary-floater-height: 37px;
$quote-summary-floater-lg-height: 61px;

.page-heading {
  margin-top: 28px;
  margin-bottom: 36px;

  h2 {
    margin: 0;
    text-align: center;
    font-size: 1.875rem;
  }

  h3 {
    margin: 0;
    margin-top: $spacing-s;
    text-align: center;
  }
}

#page-wrapper {
  // padding-bottom: $quote-summary-floater-height + 24px;

  // @include media-breakpoint-up(lg) {
  //   padding-bottom: $quote-summary-floater-lg-height + 24px;
  // }
}

.container-main {
  @extend .container-fluid;

  @include media-breakpoint-only(lg) {
    max-width: 960px;
  }
  @include media-breakpoint-only(xl) {
    max-width: 1140px;
  }

  &__inner {
    @extend .container;
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-between(sm, md) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.custom-select {
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%239e8146' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
}

.display-4 {
  font-size: 46px;
  font-weight: 600;
  font-family: canada-type-gibson;
  line-height: 1.2;
}
