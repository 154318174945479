.autocomplete-dropdown-container {
  top: 100%;
  right: 0;
}

.dropdown {
  &-item {
    padding: 0.25rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.venueInput {
  position: relative;
  margin-bottom: 1rem;

  & input {
    padding-right: 45px;
    border-radius: 0;
  }

  & .fa-search {
    position: absolute;
    bottom: 10px;
    right: 16px;
    font-size: 22px;
    z-index: 5;
  }

  & .btn {
    position: absolute;
    right: 5px;
    border: none;
    z-index: 5;

    & .fa-times {
      font-size: 24px;
      line-height: 1;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .form-control[readonly] {
    background: $c-white;
    font-weight: bold;
  }
}
