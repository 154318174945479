.underwriting-questions {
  &__form-container {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
  }

  & .page-heading h2 {
    text-align: left;
  }

  &__field {
    margin-top: 3rem;

    @include media-breakpoint-down(sm) {
      margin-top: 1rem;
      flex-direction: column;
    }
  }

  &__text {
    padding-right: 2rem;

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }

  &__select-container {
    width: calc(200px + 1.5rem);

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 1rem;
    }
  }

  &__radio {
    width: 100px;
    height: 50px;
    padding: 0 0.75rem;
    line-height: 50px;
  }

  &__continue {
    margin-top: 34px;
    margin-bottom: 24px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .btn {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 303px;
    }
  }

  &__controls {
    display: block;

    @media (max-width: 576px) {
      margin-top: 1rem;
    }

    & .form-error {
      @media (min-width: 768px) {
        padding-left: 1.5rem;
      }
    }

    & .col-auto:first-child {
      & .custom-radio {
        @media (max-width: 768px) {
          padding-left: 0;
        }
      }
    }

    & .custom-control-input {
      & + .btn {
        background-color: #fff;
        border: 1px solid #333;
        color: #333;
        border-radius: 35px;
      }

      &:focus + .btn {
        outline: 2px solid #333;
      }

      &:checked {
        & + .btn {
          background-color: #333;
          border: 2px solid #333;
          color: #fff;

          &--error {
            color: #0d3a68;
            background-color: #ecf0f7;
            border-color: #0d3a68;
            border-width: 1px;
          }
        }
      }

      &:hover:not(:checked) {
        & + .btn {
          background-color: #333;
          border: 2px solid #333;
          color: #fff;
        }
      }
    }
  }
}
