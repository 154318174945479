.event-card {
  padding: 0;
  background: #fff;
  border: none;
  border-radius: 16px;
  width: 100%;
  margin: 10px 0;
  color: #333;
  box-shadow: 0 0 0 1px #333 !important;
  overflow: hidden;
  outline: none;

  p {
    color: #333;
  }

  &--selected,
  &:focus,
  &:active {
    outline: none;
    background: #8f62ff;
    box-shadow: 0 0 0 3px #8f62ff !important;
  }

  @media (min-width: 992px) {
    display: flex;
    flex-direction: column;
    height: 350px;
    width: 220px;
  }

  @media (min-width: 1200px) {
    height: 404px;
    width: 257px;
  }

  &__image {
    display: none;

    @media (min-width: 992px) {
      display: block;
      width: 100%;
      height: 136px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__copy {
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 992px) {
      flex: 1;
      width: 100%;
    }
  }

  &__title {
    font-weight: bold;
  }

  &__divider {
    height: 2px;
    width: 100px;
    background-color: transparent;
    margin: 1rem 0;

    @media (min-width: 992px) {
      position: absolute;
      top: 210px;
    }

    @media (min-width: 1200px) {
      top: 223px;
    }
  }

  .event-card__sub-title {
    @media (min-width: 992px) {
      margin-top: auto;
      margin-bottom: 12px;
    }
  }

  .event-card__select-btn {
    display: none;

    @media (min-width: 992px) {
      display: block;
      width: 140px;
    }
  }
}
