a {
  transition: all 0.4s ease;
  &:hover {
    color: #86cf59;
  }
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;

  @include media-breakpoint-up(md) {
    font-size: 2.8125rem;
  }
}

h3 {
  font-size: 1rem;
  font-weight: bold;
  line-height: normal;

  @include media-breakpoint-up(sm) {
    font-size: 25px;
  }

  @include media-breakpoint-up(md) {
    font-size: 30px;
  }
}

p,
.responsive-font-size {
  font-size: 1rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.0625rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
  }
}

.container {
  @include media-breakpoint-only(xs) {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

.continue-btn {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  @include media-breakpoint-up(lg) {
    max-width: 408px;
  }
}

label,
.label {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}

.modal.fade.show {
  padding-left: 0 !important;
}

.summary-table {
  &__item {
    display: flex;
    margin-bottom: 16px;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 50% 50%;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 24px;
    }

    &--md-75-25 {
      @include media-breakpoint-up(md) {
        grid-template-columns: 75% 25%;
      }
    }

    &-additional-info {
      font-size: 0.8rem;
    }
  }

  &__item-value {
    white-space: pre-line;
  }
}
