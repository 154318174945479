.event-selection {
  &__page-heading {
    margin-bottom: 0;
  }

  h2 {
    font-size: 1.875rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    margin-top: 40px;
  }

  h3 {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
      margin-top: 1rem;
    }
  }

  .alert {
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
      max-width: 686px;
    }

    @include media-breakpoint-up(xl) {
      width: 805px;
    }
  }

  &__card-container {
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: $spacing-l;
      margin-left: auto;
      margin-right: auto;
      width: 686px;
    }

    @include media-breakpoint-up(xl) {
      width: 805px;
    }
  }

  &__select-menu-container {
    @include media-breakpoint-up(lg) {
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
    }
  }

  &__cannabis {
    max-width: 581px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 34px;
    }
  }

  &__cannabis-radio {
    width: 200px;
    margin: 0 auto;
  }
}
