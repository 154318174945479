.limits {
  &__form-container {
    margin-top: 2.375rem;
    margin-bottom: 2.5rem;
  }

  &__form-row {
    padding-top: 1rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $c-gray-300;
  }

  &__terrorism-agreement {
    & > .custom-control-label {
      font-size: 1.25rem;
    }
  }

  &__terrorism-disclosure p {
    @include media-breakpoint-down(sm) {
      font-size: 13px;
    }
  }
}
