.nav-back-button {
  font-size: 1rem;
  cursor: pointer;

  &__back {
    text-transform: uppercase;
    letter-spacing: 3.64px;
    margin-right: 1rem;
    font-family: Object sans, sans-serif;
  }

  &__steps {
    font-family: Object sans, sans-serif;
  }

  i {
    margin-right: 0.25rem;
  }
}
