.additional-insured {
  &__cert-holders {
    padding-bottom: 16px;
    margin-top: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid $c-blue-secondary;
    border-top: 1px solid $c-gray-500;

    @include media-breakpoint-up(sm) {
      border-top: none;
      padding-bottom: 24px;
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 50px;
      margin-top: 35px;
      margin-bottom: 48px;
    }
  }

  &__cert-holder {
    display: flex;
    justify-content: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;

    &:last-child {
      padding-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      padding: 0 48px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $c-gray-500;
      border-right: none;

      @include media-breakpoint-up(md) {
        border-bottom: none;
        border-right: 1px solid $c-gray-500;
      }
    }

    & .buttons-place {
      position: absolute;
      width: 70px;
      padding: 0 5px;
      right: 0;
      top: -5px;
      display: flex;
      height: 100%;
      align-items: flex-end;
      justify-content: flex-end;

      & .edit-button {
        height: 30px;
        background-color: $white;
        color: #8f62ff !important;
        border: none;
        padding: 0;
        margin-left: 10px !important;
        margin-right: 10px !important;

        &:hover:not(:disabled),
        &:focus:not(:disabled),
        &:active:not(:disabled) {
          color: #5816ff !important;
          background-color: $white;
        }
      }

      & .delete-button {
        height: 30px;
        background-color: $white;
        color: #cf3a2f !important;
        border: none;
        padding: 0;
        margin-left: 10px !important;
        margin-right: 10px !important;

        &:hover:not(:disabled),
        &:focus:not(:disabled),
        &:active:not(:disabled) {
          color: #c11a2f !important;
          background-color: $white;
        }
      }
    }
  }

  & .add-button {
    border: 4px solid $additional-insured-add-button;
    border-radius: 20px;
    color: $additional-insured-add-button !important;
    background-color: $white;
    font-size: 30px;

    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &:active:not(:disabled) {
      background-color: $additional-insured-hover-button-background;
      border: 4px solid $additional-insured-add-button;
    }
  }

  .continue-btn {
    margin-top: 32px;

    @include media-breakpoint-up(lg) {
      margin-top: 40px;
    }
  }

  & .additional-text {
    font-size: medium;
  }
}
