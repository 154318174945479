.footer {
  margin-top: $spacing-l;

  &__copyright {
    font-size: 12px;
    color: #fff;

    @include media-breakpoint-up(md) {
      top: 5px;
    }
  }

  &__navigation {
    @media (max-width: 768px) {
      flex-direction: column;
    }

    &-item {
      flex-grow: 1;
      margin-bottom: 12px;
      @media (max-width: 768px) {
        margin-bottom: 25px;
      }
    }
  }

  &__legal {
    font-size: 12px;
    margin-top: 25px;
    margin-bottom: 15px;
    color: #fff;
  }
}

footer {
  padding: 75px 0;
  background: #000;
  border-radius: 24px 24px 0 0;

  & .container {
    @include media-breakpoint-up(xl) {
      max-width: 1140px;
    }
    @media (min-width: 1440px) {
      max-width: 1330px;
    }

    & .wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      & > * {
        padding: 0 15px;
        width: 100%;
      }

      & .nav {
        justify-content: space-between;
        flex-wrap: nowrap;

        & .nav-item {
          margin-bottom: 25px;

          @media (min-width: 768px) {
            margin: 0 15px;
          }

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  & a {
    transition: all 0.4s ease;
    color: #fff;
    &:hover {
      color: #86cf59;
    }
  }
}
