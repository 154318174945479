.navigation {
  &__nav-links {
    flex-direction: column;
    padding: 0 30px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      padding: 0;
    }
  }
  &__nav-link-item {
    list-style: none;
    text-transform: uppercase;
    display: flex;

    @include media-breakpoint-up(lg) {
      text-transform: none;
      padding-left: $spacing-l;
    }

    a {
      width: 100%;
      display: flex;
      align-items: center;
      color: $c-blue-main;
      font-size: 1rem;
      font-weight: normal;
      margin-top: auto;
      padding-right: 0;
      padding-left: 0;
      margin-top: 0;
      text-align: left;

      &:hover {
        color: $c-gold-light-secondary;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }

      @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.25rem;
      }
    }
  }
}
