.event-information {
  &__form-container {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
  }

  & .page-heading h2 {
    text-align: left;
  }

  &__form {
    max-width: 670px;
  }

  &__guests input {
    @include media-breakpoint-up(md) {
      width: 266px;
    }
  }

  &__wedding-label {
    padding-left: 32px;
    padding-top: 4px;
  }

  &__wedding-questions {
    .custom-control-label {
      &::before,
      &::after {
        @include media-breakpoint-up(lg) {
          top: 5px;
        }
      }
    }
  }

  &__info-text {
    font-size: 0.675rem;
    margin-top: -0.2rem;

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }
  }
}
