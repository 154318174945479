.samplePolicy {
  &__card {
    border-color: #a2a3a5;
  }
  &__link {
    font-weight: 500;
    color: #5816ff;
    &:hover {
      text-decoration: underline;
    }
  }
  &__border {
    border-top: 1px solid #a2a3a5;
  }
}
