.radio-control-group {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  .custom-control-inline {
    margin-right: 0;
  }
}
// Customizations
.text-center {
  .radio-control-group {
    flex-wrap: nowrap;
    justify-content: center;
  }
}
.limits__terrorism-controls {
  .radio-control-group {
    .custom-control-inline {
      margin-right: 1rem;
      @include media-breakpoint-down(sm) {
        flex-basis: 100%;
      }
    }
  }
}
