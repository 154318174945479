.venue {
  &__heading {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      font-size: 2.8125rem;
      margin-bottom: 1.5rem;
    }
  }

  &__images {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      padding-top: 8px;
      margin-left: auto;
      margin-right: auto;
      height: 166px;
      width: 100%;
      max-width: 1440px;
    }

    @include media-breakpoint-up(xl) {
      height: 233px;
    }
  }

  &__clear-btn {
    font-size: 1rem;
  }

  &__image {
    height: 100%;
    flex: 1;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__sub-heading-wrapper {
    margin-top: 40px;

    &--with-divider {
      position: relative;

      & .venue__divider {
        position: absolute;
        top: 23px;
      }
    }
  }

  &__sub-heading {
    color: $c-blue-secondary;
    font-size: 1rem;
    font-weight: bold;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    text-align: center;
    position: relative;
    background: $c-white;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      font-size: 1.875rem;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__divider {
    background: $c-blue-secondary;
    height: 2px;
    width: 100%;
  }

  &__form {
    max-width: 805px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-down(lg) {
    &__form {
      max-width: 686px;
    }
  }

  &__continue {
    margin-top: 40px;
    margin-bottom: 24px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .btn {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 408px;
    }
  }

  &__restrictions {
    text-align: center;
    font-size: 12px;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      font-size: 12px;
    }
  }

  &__spinner {
    margin-left: 50%;
    margin-top: 100px;
  }

  p {
    font-size: 16px;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }
  }
}

.page-combined .venue .page-heading {
  margin: 0;
}
