.header {
  z-index: 1040;
  position: relative;

  i {
    &.fa-bars,
    &.fa-times {
      color: #fdaf13;
      transition: color 0.4s ease;

      &:hover {
        color: #8f62ff;
      }
    }
  }

  &__side-modal-backdrop {
    display: none !important;
    z-index: 1020;
  }

  &__side-modal {
    z-index: 1030;

    & .header__navigation {
      flex-direction: column;
      padding-top: 120px;
      &-item {
        margin-left: 0;
        padding: 30px 0;
      }
      @media (min-width: 992px) {
        flex-direction: row;
        &-item {
          margin-left: 30px;
        }
      }
    }
  }

  &__dialog {
    max-width: 100vw;
    max-height: 100vh;
    margin: 0;

    .modal-content {
      $side-spacing: 0px;
      width: calc(100vw - $side-spacing);
      height: 100vh;
      position: relative;
      left: $side-spacing;
      border-radius: 0;
      border: none;
    }
  }

  & .navbar-brand {
    & .defaultLogo {
      width: 110px;
    }
    & .customLogo {
      max-width: 500px;
      max-height: 100px;
      @media only screen and (max-width: 600px) {
        max-width: 50vw;
      }
    }
  }

  &__bars {
    font-size: 28px;
  }

  &__banner {
    height: 60px;
    display: flex;
    align-items: center;
    color: $c-white;
    background-color: $c-blue-main;

    h1 {
      font-size: 1.5rem;
      font-family: $font-family-merriweather;
      font-weight: normal;
      margin-left: 0;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      height: 125px;

      h1 {
        font-size: 51px;
        margin-left: 3rem;
      }
    }

    @include media-breakpoint-up(xl) {
      h1 {
        font-size: 60px;
      }
    }
  }

  &__navigation {
    flex-direction: column;
    &-item {
      margin-left: 0;
      padding: 30px 0;
    }
    @media (min-width: 992px) {
      flex-direction: row;
      &-item {
        margin-left: 30px;
      }
    }
    & a {
      transition: all 0.4s ease;
      &:hover {
        color: #86cf59;
      }
    }
  }
}

.modal.fade:not(.show) .modal-dialog.header__dialog {
  transform: translate(1000px, 0);
}
