.quote-summary-floater {
  position: sticky;
  bottom: 0;
  z-index: 1;
  margin-top: $spacing-l;

  &__button {
    display: flex;
    align-items: center;
    border: none;
    width: 100%;
    height: $quote-summary-floater-height;
    background-color: #fdaf13;
    color: #333;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    @include media-breakpoint-up(lg) {
      height: $quote-summary-floater-lg-height;
    }
  }

  &__btn-copy {
    color: #333;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: #fdaf13;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    span:first-of-type {
      position: relative;
      top: 5px;

      @include media-breakpoint-up(lg) {
        top: 14px;
      }
    }
  }

  &__total {
    position: absolute;
    right: 0;
    font-size: 1.25rem;
    padding: 0 16px;
    color: #333;
    font-weight: 500;
    background-color: transparent;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 121px;

    @include media-breakpoint-up(lg) {
      right: auto;
    }
  }

  &__dialog {
    max-width: 100vw;
    max-height: 100vh;
    margin: 0;

    .price-summary-table {
      padding-bottom: 60px;
    }

    .modal-content {
      height: calc(100vh - $price-summary-footer-height);
      position: relative;
      top: $price-summary-footer-height;
      border-radius: 0;
      border: none;
      padding-bottom: $price-summary-footer-height;

      @include media-breakpoint-up(lg) {
        height: calc(100vh - $price-summary-footer-height);
        position: relative;
        top: $price-summary-footer-height;
        padding-bottom: $price-summary-footer-height;
      }
    }
  }

  &__arrow {
    position: absolute;
    left: 50%;
    top: -24px;
    margin-left: -12px;
    color: #333;
    font-size: 24px;
  }

  &__arrow-close {
    position: fixed;
    left: 50%;
    top: 17px;
    margin-left: -12px;
    font-size: 24px;
    border: none;
    background: none;
    z-index: 1;
    padding: 0;
    color: #86cf59;
  }
}

.modal.fade:not(.show) .modal-dialog.quote-summary-floater__dialog {
  transform: translate(0, 1000px);
}
