$price-summary-footer-height: 60px;

.price-summary-footer {
  background-color: $c-white;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;

  &__premium-total {
    line-height: $price-summary-footer-height;
  }

  &__legal {
    font-weight: 400;
  }

  &__legal-heading {
    font-weight: 700;
  }

  &__legal-link {
    color: #86cf59;
  }

  &__legal-text {
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }

  &__save-quote-btn {
    margin-bottom: 18px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}
